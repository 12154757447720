
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '../public/static/style.scss';
import localFont from 'next/font/local';
import { setProgressbar } from 'utils/nprogress';
setProgressbar();
const queryClient = new QueryClient();
const myFont = localFont({
    src: [
        {
            path: '../public/fonts/GreycliffCF-Regular.woff2',
            weight: '300',
            style: 'normal',
        },
        {
            path: '../public/fonts/GreycliffCF-RegularOblique.woff2',
            weight: '300',
            style: 'italic',
        },
        {
            path: '../public/fonts/GreycliffCF-Medium.woff2',
            weight: '400',
            style: 'normal',
        },
        {
            path: '../public/fonts/GreycliffCF-MediumOblique.woff2',
            weight: '400',
            style: 'italic',
        },
        {
            path: '../public/fonts/GreycliffCF-Bold.woff2',
            weight: '700',
            style: 'normal',
        },
        {
            path: '../public/fonts/GreycliffCF-BoldOblique.woff2',
            weight: '700',
            style: 'italic',
        },
    ],
    display: 'swap',
    preload: true,
});
export const FONT_STYLE = {
    __html: `
  :root {
    --font-greycliff: ${myFont.style.fontFamily};
  }`,
};
const SSPApp = (props): ReactElement => {
    const { Component, pageProps } = props;
    const getLayout = Component.getLayout || (page => page);
    return (<QueryClientProvider client={queryClient}>
      <style dangerouslySetInnerHTML={FONT_STYLE}/>
      <ReactQueryDevtools initialIsOpen={false}/>
      {getLayout(<Component {...pageProps}/>)}
    </QueryClientProvider>);
};
const __Next_Translate__Page__190e526e013__ = SSPApp;

    export default __appWithI18n(__Next_Translate__Page__190e526e013__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  